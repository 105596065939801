import React from "react";
import Footer from "../components/Footer";
// import '../style/team.css'
import { Zoom } from "react-awesome-reveal";
import { metaTagsData } from "../routes/metaTagsData";
import { AppRoutes as routes } from "../routes/routes";
import { SeoComponentsBuilder } from "../components/seoComponent/SeoComponentsBuilder";

const About = () => {
  return (
    <>
      <SeoComponentsBuilder metaTags={metaTagsData[routes.about]} />
      <Zoom triggerOnce={true}>
        <br></br>
        <br></br>
        <br></br>

        <div className="container mb-5">
          <h2 className="title">
            <b>About Us</b>
          </h2>
          <section className="about-me" id="about-me">
            <div className="about-me-container">
              {/* <div className="about-me-title text-center">
            About Us <br /> <br></br>
          </div>  */}

              <div className="about-me-flex-container">
                <div className="about-me-image">
                  <div className="back-div"></div>
                  <div className="black-image">
                    <img src="./images/aboutlogo.jpg" alt="black" />
                  </div>
                </div>
                <div className="about-me-content">
                  <div className="text">
                    <h2>Vision</h2>
                    We providing free food and most affordable food A free food
                    service vision might include a variety of aims, such as
                    resolving food hunger and advancing environment and
                    community well-being.
                    <br />
                    <br />
                    <h2>Community</h2>
                    To build an active community with no hunger while upholding
                    the environment, justice, and dignity via the provision of
                    free, soul-satisfying meals.
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
          <br />
          <h2>Equality</h2>
          We consider the availability of healthy food to be a basic human right
          rather than something special. In order to guarantee that everyone in
          our community has access to the food they require to survive, our
          service attempts to close access gaps.
          <br />
          <br />
          <h2>Goals</h2>
          End Hunger: Reducing hunger in our community is our main priority. Our
          free food and affordable program aims to guarantee that no person or
          family goes without good food.
          <br />
          <br />
          <h2>Empowerment</h2>
          By offering resources and details on community services, and food, we
          hope to empower people. Members in our community are now more equipped
          to make decisions
          <br />
          <br />
          <h2>Collaboration</h2>
          We think that partnerships and franchise have great power. We work
          together with nearby farms, companies, volunteer organizations, and
          nonprofits to increase our impact and assist more individuals in need.
        </div>
      </Zoom>
      {/* </div> */}
    </>
  );
};

export default About;
