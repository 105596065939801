import React from 'react';
import { Zoom } from "react-awesome-reveal";
import { AppRoutes } from '../routes/routes';
import { metaTagsData } from '../routes/metaTagsData';
import { SeoComponentsBuilder } from '../components/seoComponent/SeoComponentsBuilder';
const PolicyPage = () => {
    return (
        <>
      <SeoComponentsBuilder metaTags={metaTagsData[AppRoutes.privacypolicy]} />

            <Zoom triggerOnce={true}>
            <br></br> 
            <br></br>
            <br></br> 
            <br></br>
                <section>
                <div className='app'>


<h1 className='text-center text-dark app '><b>Welcome to bhandara app</b></h1>
</div>

                    <div className="container">
                        <div className="privacy-policy-inr-wrapper">
                          
                            <h4 className="text-center mt-4 "><b>PRIVACY POLICY</b></h4>

                            <p className="">Last updated: 22-MAY-2024</p>
                            <p className="pb-3 text-left">This Privacy Policy describes Our policies and procedures on the collection,
                                use and disclosure of Your information when You use the Service and tells You
                                about Your privacy rights and how the law protects You.</p>
                            <p>Bhandara("us", "we", or "our") operates <a href="https://bhandara.app">https://bhandara.app</a> (hereinafter referred to as "Service")</p>
                            <p className="pb-3 text-left">bhandara.app Registered under sole proprietorship,govt MSME.</p>
                            <p className="pb-3 text-left">Our Privacy Policy governs your visit to https://bhandara.app/, and explains how we collect, safeguard and disclose information that results from your use of our Service.</p>
                            <p className="pb-3 text-left">We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions. </p>

                            <div className="btm-policies-div">
                                <h4 className="pb-3 text-left text-dark"><u><b>Definitions:</b></u></h4>
                                <p className="pb-3 text-left">For the purposes of this Privacy Policy:</p>
                                <p className="pb-3 text-left">Bhandara. The app is a free food search app, by using it you can find out the location of where the food is available for free and you can easily go to the location using Google Map.</p>
                                <ul>
                                    <li>User can give information about free food in two ways on the app, Recent updates and everyday.</li>
                                    <li>Recent updates- In this, the free food location remains only for 2 hours after the user shares it.</li>
                                    <li>Everyday- In this, the user can share the location of free food once, then that information is visible to the user on the app daily as per the time.</li>
                                    <li></li>
                                    <p className="pb-3 text-left">User can share free food place like food place photo, food address, religion, veg or non veg, google map location, time.</p>
                                    <p className="pb-3 text-left">Community Hub in the app, in which all users can share their views only related to food and only food related photos can be shared.</p>
                                    <p className="pb-3 text-left">If any user shares sexual photos or wrong material then action can be taken against him.</p>
                                    <li>Account means a unique account created for You to access our Service or
                                        parts of our Service.</li>
                                    <li>Affiliate means an entity that controls, is controlled by or is under.</li>
                                    <li>Application refers to Bhandara.app, the software program provided by the
                                        Company.
                                    </li>
                                    <li>Company (referred to as either "the Company", "We", "Us" or "Our" in this
                                        Agreement) refers to Proprietarship , New Delhi .</li>
                                    <li>Country refers to: Delhi, India</li>
                                    <li>Device means any device that can access the Service such as a computer, a
                                        cellphone or a digital tablet</li>
                                    <li>Personal Data is any information that relates to an identified or
                                        identifiable individual.</li>
                                    <li>Service refers to the Application.</li>
                                    <li>Service Provider means any natural or legal person who processes the data
                                        on behalf of the Company. It refers to third-party companies or
                                        individuals employed by the Company to facilitate the Service, to provide
                                        the Service on behalf of the Company, to perform services related to the
                                        Service or to assist the Company in analyzing how the Service is used.</li>
                                    <li>Usage Data refers to data collected automatically, either generated by the
                                        use of the Service or from the Service infrastructure itself (for example,
                                        the duration of a page visit).</li>
                                    <li>You means the individual accessing or using the Service, or the company,
                                        or other legal entity on behalf of which such individual is accessing or
                                        using the Service, as applicable.</li>
                                    <p className="pb-3 text-left"><u>Collecting and Using Your Personal Data  </u></p>
                                    <p className="pb-3 text-left"><b>yes of Data Collected  </b></p>

                                    <h4 className="pb-3 text-left text-dark"><b><u>Location Permission Required:</u></b></h4>
                                    <div>
                                        <ol>
                                            <li>
                                                <h4 className='text-dark'>Introduction</h4>
                                            </li>
                                            <p pb-3 text-left>Bhandara ("we," "our," or "us") operates Bhandara App (the "App"). This Privacy Policy informs you of our policies regarding the collection, use, and disclosure of personal information we receive from users of the App. We are committed to protecting your privacy and ensuring the security of your personal information.</p>
                                            <li><h4 className='text-dark'>Information Collection and Use</h4></li><br></br>
                                            <h4 className='text-dark'>2.1 Location Information</h4>
                                            <p className="pb-1">To provide our users with the best experience, the App requests access to your device's location services. We require your location to enable you to share the current location of free food items via Google Maps. This allows other users to find and visit those locations using our App. Please note that we do not share your specific location information with other users. Your location data is only used within the App for the purpose of facilitating the sharing of free food items.</p>
                                            <h4 className='text-dark text-left'>2.2 Non-Personal Information</h4>
                                            <p pb-1>We may collect non-personal information about your usage of the App, such as the type of device you are using, the operating system, and usage statistics. This information is collected to improve the functionality and user experience of the App.</p>
                                            <li><h4 className='text-dark'> Data Security</h4></li>
                                            <p className="pb-1">We prioritize the security of your personal information. We have implemented appropriate technical and organizational measures to safeguard your data from unauthorized access, disclosure, alteration, and destruction. All data collected by the App is stored securely and is accessible only to authorized personnel.</p>
                                            <li><h4 className='text-dark'>Data Sharing</h4></li>
                                            <p className="pb-1">We do not share your specific location information with other users or third parties. All user data, including location information, is treated as confidential and is not disclosed to anyone outside the company unless required by law.</p>
                                            <li><h4 className='text-dark text-left'>Changes to this Privacy Policy</h4></li>
                                            <p className="pb-1">This Privacy Policy is effective as of the date stated at the beginning of this document and will remain in effect except concerning any changes in its provisions in the future, which will be in effect immediately after being posted on this page. We reserve the right to update or change our Privacy Policy at any time. You should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</p>
                                        </ol>
                                    </div>

                                    <h4 className="pb-3 text-left text-dark">Personal Data</h4>
                                    <p className="pb-3 text-left">While using Our Service, We may ask You to provide Us with certain personally
                                        identifiable information that can be used to contact or identify You.
                                        Personally identifiable information may include, but is not limited to:</p>
                                    <li>Email address</li>
                                    <li>First name and last name</li>
                                    <li>Phone number</li>
                                    <li>Usage Data</li>

                                    <p className="pb-3 text-left text-dark">Usage Data is collected automatically when using the Service.</p>
                                    <p className="pb-3 text-left">Usage Data may include information such as Your Device's Internet Protocol
                                        address (e.g. IP address), browser type, browser version, the pages of our
                                        Service that You visit, the time and date of Your visit, the time spent on
                                        those pages, unique device identifiers and other diagnostic data.</p>
                                    <p className="pb-3 text-left">When You access the Service by or through a mobile device, We may collect
                                        certain information automatically, including, but not limited to, the type of
                                        mobile device You use, Your mobile device unique ID, the IP address of Your
                                        mobile device, Your mobile operating system, the type of mobile Internet
                                        browser You use, unique device identifiers and other diagnostic data.</p>
                                    <p className="pb-3 text-left">We may also collect information that Your browser sends whenever You visit our
                                        Service or when You access the Service by or through a mobile device.</p>
                                    <h4 className="pb-3 text-left  text-dark"><u>Information Collected while Using the Application </u></h4>
                                    <p>While using Our Application, in order to provide features of Our Application,
                                        We may collect, with Your prior permission:</p>
                                    <li>Information regarding your location</li>
                                    <li>Pictures and other information from your Device's camera and photo library</li>
                                    <p className="pb-3 text-left">We use this information to provide features of Our Service, to improve and
                                        customize Our Service. The information may be uploaded to the Company's
                                        servers and/or a Service Provider's server or it may be simply stored on Your
                                        device.</p>
                                    <p className="pb-3 text-left">You can enable or disable access to this information at any time, through Your
                                        Device settings.</p>
                                    <h4 className="pb-3 text-left text-dark"><u>Use of Your Personal Data  </u></h4>
                                    <p className="pb-3 text-left">The Company may use Personal Data for the following purposes:</p>
                                    <li>To provide and maintain our Service , including to monitor the usage of
                                        our Service.</li>
                                    <li>To manage Your Account: to manage Your registration as a user of the
                                        Service. The Personal Data You provide can give You access to different
                                        functionalities of the Service that are available to You as a registered
                                        user.</li>
                                    <li>For the performance of a contract: the development, compliance and
                                        undertaking of the purchase contract for the products, items or services
                                        You have purchased or of any other contract with Us through the Service.</li>
                                    <li>To contact You: To contact You by email, telephone calls, SMS, or other
                                        equivalent forms of electronic communication, such as a mobile
                                        application's push notifications regarding updates or informative
                                        communications related to the functionalities, products or contracted
                                        services, including the security updates, when necessary or reasonable for
                                        their implementation.</li>
                                    <li>To provide You with news, special offers and general information about
                                        other goods, services and events which we offer that are similar to those
                                        that you have already purchased or enquired about unless You have opted
                                        not to receive such information.</li>
                                    <li>To manage Your requests: To attend and manage Your requests to Us.</li>
                                    <li>For business transfers: We may use Your information to evaluate or conduct
                                        a merger, divestiture, restructuring, reorganization, dissolution, or
                                        other sale or transfer of some or all of Our assets, whether as a going
                                        concern or as part of bankruptcy, liquidation, or similar proceeding, in
                                        which Personal Data held by Us about our Service users is among the assets
                                        transferred.</li>
                                    <li>For other purposes : We may use Your information for other purposes, such
                                        as data analysis, identifying usage trends, determining the effectiveness
                                        of our promotional campaigns and to evaluate and improve our Service,
                                        products, services, marketing and your experience.</li>
                                    <p className="pb-3 text-left">We may share Your personal information in the following situations:</p>
                                    <li>With Service Providers: We may share Your personal information with
                                        Service Providers to monitor and analyze the use of our Service, to
                                        contact You.</li>
                                    <li>For business transfers: We may share or transfer Your personal information
                                        in connection with, or during negotiations of, any merger, sale of Company
                                        assets, financing, or acquisition of all or a portion of Our business to
                                        another company.</li>
                                    <li>With Affiliates: We may share Your information with Our affiliates, in
                                        which case we will require those affiliates to honor this Privacy Policy.
                                        Affiliates include Our parent company and any other subsidiaries, joint
                                        venture partners or other companies that We control or that are under
                                        common control with Us.</li>
                                    <li>With business partners: We may share Your information with Our business
                                        partners to offer You certain products, services or promotions.</li>
                                    <li> With other users: when You share personal information or otherwise
                                        interact in the public areas with other users, such information may be
                                        viewed by all users and may be publicly distributed outside.</li>
                                    <li>With Your consent : We may disclose Your personal information for any
                                        other purpose with Your consent.</li>
                                    <h4 className="pb-3 text-left text-dark"><u>Retention of Your Personal Data</u></h4>

                                    <p className="pb-3 text-left">The Company will retain Your Personal Data only for as long as is necessary
                                        for the purposes set out in this Privacy Policy. We will retain and use Your
                                        Personal Data to the extent necessary to comply with our legal obligations
                                        (for example, if we are required to retain your data to comply with applicable
                                        laws), resolve disputes, and enforce our legal agreements and policies.</p>
                                    <p className="pb-3 text-left">The Company will also retain Usage Data for internal analysis purposes. Usage
                                        Data is generally retained for a shorter period of time, except when this data
                                        is used to strengthen the security or to improve the functionality of Our
                                        Service, or We are legally obligated to retain this data for longer time
                                        periods.</p>
                                    <h4 className="pb-3 text-left text-dark"><u>Transfer of Your Personal Data</u></h4>
                                    <p className="pb-3 text-left">Your information, including Personal Data, is processed at the Company's
                                        operating offices and in any other places where the parties involved in the
                                        processing are located. It means that this information may be transferred to —
                                        and maintained on — computers located outside of Your state, province, country
                                        or other governmental jurisdiction where the data protection laws may differ
                                        than those from Your jurisdiction.</p>
                                    <p className="pb-3 text-left">Your consent to this Privacy Policy followed by Your submission of such
                                        information represents Your agreement to that transfer.</p>
                                    <p className="pb-3 text-left">The Company will take all steps reasonably necessary to ensure that Your data
                                        is treated securely and in accordance with this Privacy Policy and no transfer
                                        of Your Personal Data will take place to an organization or a country unless
                                        there are adequate controls in place including the security of Your data and
                                        other personal information.</p>
                                    <h4 className="pb-3 text-left text-dark"><u>Delete Your Personal Data </u></h4>
                                    <p className="pb-3 text-left">You have the right to delete or request that We assist in deleting the
                                        Personal Data that We have collected about You.</p>
                                    <p className="pb-3 text-left">Our Service may give You the ability to delete certain information about You
                                        from within the Service.</p>
                                    <p className="pb-3 text-left">You may update, amend, or delete Your information at any time by signing in to
                                        Your Account, if you have one, and visiting the account settings section that
                                        allows you to manage Your personal information. You may also contact Us to
                                        request access to, correct, or delete any personal information that You have
                                        provided to Us.</p>
                                    <h4 className="pb-3 text-left text-dark"><u>Deletion your account Process:</u></h4>
                                    <p className="pb-3 text-left">At Bhandara, we respect your privacy and are committed to protecting your personal information. This privacy policy outlines the procedure for deleting user accounts and the measures we take to ensure the security of your data during this process.</p>
                                    <h4 className="pb-2 text-dark text-left">Account Deletion Procedure:</h4>
                                    <p className="pb-2">When you submit a request to delete your account, we require you to provide a valid registered email address and the phone number associated with your account during the creation process. This information is crucial for us to identify and validate your request accurately.</p>
                                    <h4 className="pb-2 text-dark ">Validation Process:</h4>
                                    <p className="pb-3 text-left">Upon receiving your account deletion request, our backend system performs a thorough validation process. We match the provided email address and phone number with the details associated with your account. This validation step is crucial to prevent unauthorized deletion requests and ensure the security of your account.</p>
                                    <h4 className="pb-2 text-dark ">Account Deletion Timeline:</h4>
                                    <p className="pb-3 text-left">Once your account has been successfully validated, it will be scheduled for deletion within 14 days. During this period, your account data will be flagged for removal from our systems.</p>
                                    <h4 className="pb-2 text-dark">Data Security Measures:</h4>
                                    <p className="pb-3 text-left">We employ industry-standard security measures to protect your personal information during the account deletion process. Your data is encrypted both during transmission and storage to prevent unauthorized access. Our backend processes are designed to securely handle your information, ensuring it is deleted permanently and irreversibly.</p>
                                    <h4 className="pb-2 text-dark text-left">How to Submit Your Deletion Request:</h4>
                                    <p className="pb-3 text-left">To initiate the account deletion process, please click on the following link: <a href="accountdeleterequest.html">Account Deletion Request</a>. You will be prompted to enter your registered email address and the phone number associated with your account.</p>
                                    <p className="pb-3 text-left">Please note, however, that We may need to retain certain information when we
                                        have a legal obligation or lawful basis to do so.</p>
                                    <h4 className="pb-3 text-left text-dark"><u>Disclosure of Your Personal Data </u></h4>
                                    <p className="pb-3 text-left"><b>Business Transactions </b></p>
                                    <p className="pb-3 text-left">If the Company is involved in a merger, acquisition or asset sale, Your
                                        Personal Data may be transferred. We will provide notice before Your Personal
                                        Data is transferred and becomes subject to a different Privacy Policy.</p>
                                    <p className="pb-3 text-left"><b>Law enforcement</b></p>
                                    <p className="pb-3 text-left">Under certain circumstances, the Company may be required to disclose Your
                                        Personal Data if required to do so by law or in response to valid requests by
                                        public authorities (e.g. a court or a government agency).</p>

                                    <h4 className="pb-3 text-left text-dark"><u>Other legal requirements</u></h4>
                                    <p className="pb-3 text-left">The Company may disclose Your Personal Data in the good faith belief that such
                                        action is necessary to:</p>

                                    <li>Comply with a legal obligation</li>
                                    <li>Protect and defend the rights or property of the Company</li>
                                    <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                                    <li>Protect the personal safety of Users of the Service or the public</li>
                                    <li className="pb-3 text-left">Protect against legal liability</li>

                                    <h4 className="pb-3 text-left text-dark"><u>Security of Your Personal Data  </u></h4>

                                    <p className="pb-3 text-left">The security of Your Personal Data is important to Us, but remember that no
                                        method of transmission over the Internet, or method of electronic storage is
                                        100% secure. While We strive to use commercially acceptable means to protect
                                        Your Personal Data, We cannot guarantee its absolute security.</p>
                                    <h4 className="pb-3 text-left text-dark"><u>Children's Privacy </u></h4>
                                    <p className="pb-3 text-left">Our Service does not address anyone under the age of 13. We do not knowingly
                                        collect personally identifiable information from anyone under the age of 13.
                                        If You are a parent or guardian and You are aware that Your child has provided
                                        Us with Personal Data, please contact Us. If We become aware that We have
                                        collected Personal Data from anyone under the age of 13 without verification
                                        of parental consent, We take steps to remove that information from Our
                                        servers.</p>
                                    <p className="pb-3 text-left">If We need to rely on consent as a legal basis for processing Your information
                                        and Your country requires consent from a parent, We may require Your parent's
                                        consent before We collect and use that information.</p>
                                    <h4 className="pb-3 text-left text-dark"><u>Links to Other Websites</u></h4>

                                    <p className="pb-3 text-left">Our Service may contain links to other websites that are not operated by Us.
                                        If You click on a third party link, You will be directed to that third party's
                                        site. We strongly advise You to review the Privacy Policy of every site You
                                        visit.</p>
                                    <p className="pb-3 text-left">We have no control over and assume no responsibility for the content, privacy
                                        policies or practices of any third party sites or services.</p>
                                    <h4 className="pb-3 text-left text-dark"><u>Changes to this Privacy Policy  </u></h4>
                                    <p className="pb-3 text-left">We may update Our Privacy Policy from time to time. We will notify You of any
                                        changes by posting the new Privacy Policy on this page.</p>
                                    <p className="pb-3 text-left">We will let You know via email and/or a prominent notice on Our Service, prior
                                        to the change becoming effective and update the "Last updated" date at the top
                                        of this Privacy Policy.
                                    </p>
                                    <p className="pb-3 text-left">You are advised to review this Privacy Policy periodically for any changes.
                                        Changes to this Privacy Policy are effective when they are posted on this
                                        page.</p>
                                    <h4 className="pb-3 text-left text-dark"><u>Contact Us  </u></h4>
                                    <p className="pb-3 text-left">If you have any questions about this Privacy Policy, please contact us at:</p>
                                    <p>Bhandara app</p>
                                    <p>Gwalior, Madhya Pradesh, 474002</p>
                                    <p className="pb-1">If you have any questions about this Privacy Policy, You can contact us:</p>
                                    <li>By email: contact@bhandara.app</li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-8">
                        <h4 className="text-center"><b>TERMS & CONDITIONS</b></h4>

                        <div className="term-inr-wrapper">
                            <p className="pb-3 text-left">Welcome to bhandara (“Company”, “we”, “our”, “us”)!</p>
                            <p className="pb-3 text-left">These Terms of Service (“Terms”, “Terms of Service”) govern your use of our website and app located at https://www.bhandara.app (together or individually “Service”) operated by bhandara.</p>
                            <p className="pb-3 text-left">Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages.</p>
                            <p className="pb-3 text-left">Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood Agreements, and agree to be bound of them.</p>
                            <p className="pb-3 text-left">If you do not agree with (or cannot comply with) Agreements, then you may not use the Service, but please let us know by emailing at contact@bhandara.app so we can try to find a solution. These Terms apply to all visitors, users and others who wish to access or use Service.</p>

                            <ol>
                                <h4 className='text-dark'>Banner-</h4>
                                <p className="pb-3 text-left">The banner is placed on the home screen of the app, the company can also show its own ads and sponsored ads in it, there is also an ad related link on the right side of the banner.</p>
                                <h4 className='text-dark'>Community hub-</h4>
                                <p className="pb-3 text-left">Community Hub in the app, in which all users can share their views only related to food and only food related photos can be shared, If     any user posts child abuse, sexual photos, sexual content or any kind of wrong post, then legal action can be taken against him, this is against our policy.</p>
                                <h4 className='text-dark'>Free-food sharing-</h4>
                                <p className="pb-3 text-left">The user is requested to enter the information regarding free food correctly so that no other user gets troubled or wastes time. If any user gives wrong post and information, then legal action can be taken against him.</p>
                                <p className="pb-3 text-left">The aim of every user should be to provide correct information about free food so that those who want to get free food can be helped. On giving correct information the user will get punya and coins. </p>
                                <h4 className='text-dark'>Withdrawal-</h4>
                                <p className="pb-3 text-left">If you have applied to withdraw your cash then make sure. That the information given by you about free food was correct if the information in any post was found not to be correct. Then your punya and coin will be deducted.</p>
                                <p className="pb-3 text-left">After verifying the information given by you, your cash will be received in the bank within 48 hours.</p>
                                <p className="pb-3 text-left">To withdraw cash, enter correct bank account and UPI ID.</p>
                                <h4 className='text-dark'>Login information-</h4>
                                <p className="pb-3 text-left">User should provide correct information at the time of creating account in the app so that if any problem occurs in the account in future, it can be resolved.</p>
                                <h4 className='text-dark'>Location-</h4>
                                <p className="pb-3 text-left">User should keep the GPS location of their phone on so that the app can work properly.</p>
                                <h4 className='text-dark'>Age permission-</h4>
                                <p className="pb-3 text-left">Bhandara App can be used by anyone above 13+. (he/she)</p>
                                <h4 className='text-dark'>Contact Us-</h4>
                                <p className="pb-3 text-left">If you have any questions about this TERMS AND CONDITIONS, please contact us by email: contact@bhandara.app</p>

                            </ol>

                        </div>
                    </div>
                </section>
            </Zoom>
            {/* </div> */}
        </>
    );
};

export default PolicyPage;
