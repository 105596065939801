import React from 'react';
import { Zoom } from "react-awesome-reveal";

function SecondStory() {
  return (
    <Zoom triggerOnce={true}>

    <section className="story_section py-5">
      <div className="container">
        <div className="row align-items-center flex-row-reverse">
          <div className="col-lg-6 col-md-6 col-xxl-6">
            <div className="about_img">
              <img src="/images/poori sabji 3.jpg" alt="aboutimage" />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xxl-6">
            <div className="story_content text-center">
              <h2 className="main_heading text-center">
                We make everything by hand with the best possible ingredients
              </h2>
              <p className="main_pra text-center">
              We welcome you to enjoy something beyond meals at our "Bhandara." It's a dedication to custom, a delicious activity, and a celebration of the happiness that comes from sharing a meal with others.
              </p>
              {/* <ul className="col_count">
                <li><i className="fa-solid fa-check fa-beat-fade"></i>Hand with the best possible</li>
                <li><i className="fa-solid fa-check fa-beat-fade"></i>lorem ipsum is not simply</li>
                <li><i className="fa-solid fa-check fa-beat-fade"></i>marketing real food from</li>
                <li><i className="fa-solid fa-check fa-beat-fade"></i>it has roots in a piece</li>
              </ul>
              <div className="main_btn about_btn my-4 w-auto">
                <button className="btn btn_all"><a>Learn More<i className="fa-solid fa-rotate-right ms-3"></i></a></button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
    </Zoom>
  );
}

export default SecondStory;
