import React from "react";
import Banner from "../components/Banner";
import CounterSection from "../components/CounterSection";
import FoodSection from "../components/FoodSection";
import OnlineBhandara from "../components/OnlineBhandara";
import SecondStory from "../components/SecondStory";
import StorySection from "../components/StorySection";
import Testimonial from "../components/TestiMonial";
import ThirdStory from "../components/ThirdStory";
import "../style/button.css";
import { AppRoutes } from "../routes/routes";
import { metaTagsData } from "../routes/metaTagsData";
import { SeoComponentsBuilder } from "../components/seoComponent/SeoComponentsBuilder";

function Home() {
  return (
    <>
      <SeoComponentsBuilder metaTags={metaTagsData[AppRoutes.home]} />

      <a
        target="_blank"
        rel="noreferrer"
        href="https://wa.me/message/YDMKCBPLZZ7TO1"
        className="btn-whatsapp-pulse btn-whatsapp-pulse-border text-white"
      >
        <i className="fab fa-whatsapp"></i>
      </a>

      <Banner />
      <CounterSection />
      <StorySection />
      <SecondStory />
      <ThirdStory />
      <FoodSection />
      <OnlineBhandara />
      <Testimonial />
    </>
  );
}
export default Home;
