import React from "react";
import { Zoom } from "react-awesome-reveal";

function FoodSection() {
  return (
    <Zoom triggerOnce={true}>
      <section id="freefood">
        <div id="services" className="services container-fluid">
          <h1 className="text-center text-dark app ">
            <b>How To We Use ?</b>
          </h1>

          <div className="container">
            <div className="services-row row mt-5">
              <div className="col-md-4 seric-div">
                <div className="service-card text-center">
                  <i className="fa-solid fa-users"></i>
                  <h4 className="text-center">Sign Up</h4>
                  <p>Quickly signup on our app in one click.</p>
                </div>
              </div>
              <div className="col-md-4 seric-div">
                <div className="service-card text-center">
                  <i className="fa-solid fa-utensils"></i>
                  <h4 className="text-center">Enjoy free food</h4>
                  <p>
                    Find free food locations and enjoy the food aswell as the
                    app.
                  </p>
                </div>
              </div>
              <div className="col-md-4 seric-div">
                <div className="service-card text-center">
                  <i className="fa-solid fa-location-arrow"></i>
                  <h4 className="text-center"> Post Bhandara locations</h4>
                  <p>A bhandara nearby? Post it and help others.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Zoom>
  );
}

export default FoodSection;
