import { AppRoutes as routes } from "./routes";

export const metaTagsData = {
  [routes.home]: {
    tags: [
      {
        title:
          "Bhandara App - Find and Organize Bhandara, Prasadam & Puja Food",
      },
    ],
    meta: [
      {
        name: "description",
        content:
          "Join the Bhandara App to discover and enjoy Bhandara meals, post Bhandara locations, and arrange online Bhandara. India's premier platform for free food locations, prasadam, and puja food.",
      },
      {
        name: "keywords",
        content:
          "Bhandara, Bhandara app, Bhandara karwao, Bhandara arrangements, Bhandara organiser near me, halwai, puja food, prasadam",
      },
    ],
  },

  [routes.about]: {
    tags: [
      {
        title:
          "About Bhandara App - Discover the Spirit of Community and Tradition",
      },
    ],
    meta: [
      {
        name: "description",
        content:
          "Learn about the Bhandara App and how it helps you find, post, and organize Bhandara events. Join the community and celebrate the spirit of sharing and tradition.",
      },
      {
        name: "keywords",
        content:
          "About Bhandara app, Bhandara app features, Bhandara community, Bhandara tradition",
      },
    ],
  },

  [routes.blog]: {
    tags: [
      {
        title:
          "Bhandara App Blogs - Insights on Bhandara and Traditional Meals",
      },
    ],
    meta: [
      {
        name: "description",
        content:
          "Read our blogs to get insights on Bhandara traditions, recipes for puja food, and tips for organizing Bhandara events. Stay informed and inspired with Bhandara App.",
      },
      {
        name: "keywords",
        content:
          "Bhandara blogs, traditional food blogs, Bhandara recipes, puja food tips, organizing Bhandara",
      },
    ],
  },
  [routes.contact]: {
    tags: [{ title: "Contact Bhandara App - We're Here to Help" }],
    meta: [
      {
        name: "description",
        content:
          "Get in touch with the Bhandara App team for any queries, support, or assistance in organizing or finding Bhandara events. We're here to help you celebrate community and tradition.",
      },
      {
        name: "keywords",
        content:
          "contact Bhandara app, Bhandara support, organize Bhandara, Bhandara queries",
      },
    ],
  },
  [routes.corporate]: {
    tags: [
      {
        title: "Corporate Bookings - Organize Bhandara Events for Your Company",
      },
    ],
    meta: [
      {
        name: "description",
        content:
          "Learn how Bhandara App can help you organize Bhandara events for your company. Discover the benefits of corporate bookings and celebrate community with your team.",
      },
      {
        name: "keywords",
        content:
          "Corporate Bhandara, Bhandara for companies, Bhandara events for employees, Bhandara app corporate",
      },
    ],
  },
  [routes.nri]: {
    tags: [
      {
        title: "NRI Portal - Connect with Bhandara from Anywhere in the World",
      },
    ],
    meta: [
      {
        name: "description",
        content:
          "Our NRI Portal helps you stay connected to Bhandara events and traditional meals, no matter where you are. Discover, post, and arrange Bhandara from abroad.",
      },
      {
        name: "keywords",
        content:
          "NRI Bhandara, Bhandara for NRIs, arrange Bhandara online, Bhandara app for NRIs",
      },
    ],
  },
  [routes.ondc]: {
    tags: [
      {
        title: "ONDC - Spiritual & Free Food Location Sharing on Bhandara App",
      },
    ],
    meta: [
      {
        name: "description",
        content:
          "Bhandara App's ONDC feature allows you to find and share free food locations with ease. Join the spiritual journey and enjoy Bhandara meals anywhere.",
      },
      {
        name: "keywords",
        content:
          "ONDC, spiritual ONDC, free food location app, Bhandara sharing app, Bhandara app",
      },
    ],
  },
  [routes.privacypolicy]: {
    tags: [{ title: "Privacy Policy - Your Guide to Using Bhandara App" }],
    meta: [
      {
        name: "description",
        content:
          "Read our privacy policy to understand how Bhandara App collects, uses, and protects your data. Learn how we keep your information safe and secure.",
      },
      {
        name: "keywords",
        content:
          "Bhandara App privacy policy, data protection, user information, privacy terms",
      },
    ],
  },
  [routes.team]: {
    tags: [{ title: "Meet the Team Behind Bhandara App" }],
    meta: [
      {
        name: "description",
        content:
          "Get to know the team that brings you the Bhandara App. Meet our dedicated professionals and learn about the people who make our platform possible.",
      },
      {
        name: "keywords",
        content:
          "Bhandara App team, Bhandara App professionals, Bhandara App developers",
      },
    ],
  },
  [routes.products]: {
    title: "Bhandara App Products - Quality Ingredients for Traditional Meals",
    description:
      "Explore our range of high-quality ingredients for preparing traditional Bhandara meals. From halwai items to prasadam, we have everything you need to honor age-old culinary customs.",
    keywords:
      "Bhandara products, halwai ingredients, traditional food, prasadam ingredients, puja food",
  },
};
