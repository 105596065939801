import React, { useEffect } from 'react';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import { Zoom } from "react-awesome-reveal";
const ClientsSection = () => {
  useEffect(() => {
    // Check if the Swiper element exists before initializing
    const swiperElement = document.querySelector('.clients-slider');
    if (swiperElement) {
      const swiper = new Swiper(swiperElement, {
        speed: 400,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        slidesPerView: 'auto',
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 40
          },
          480: {
            slidesPerView: 3,
            spaceBetween: 60
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 80
          },
          992: {
            slidesPerView: 6,
            spaceBetween: 120
          }
        }
      });

      // Destroy Swiper instance when component unmounts
      return () => {
        swiper.destroy();
      };
    }
  }, []);


  return (

    <Zoom triggerOnce={true}>
    <section id="clients" className="clients">
    <div className='app'>


<h1 className='text-center text-dark app '><b>Media Mentions</b></h1>
</div>

      <div className="container mt-4" data-aos="zoom-out">
        <div className="clients-slider swiper">
          <div className="swiper-wrapper align-items-center">
           
            <div className="swiper-slide">
              <img src="https://static.thelallantop.com/images/post/1695637095493_lallantop-logo_(1).webp?width=120" className="img-fluid" alt="" />
            </div>
            <div className="swiper-slide">
              <img src="./images/News18 Logo.jpg" className="img-fluid" alt="" />
            </div>
            <div className="swiper-slide">
              <img src="./images/one.jpg" className="img-fluid" alt="" />
            </div>
            <div className="swiper-slide">
              <img src="./images/24.jpg" className="img-fluid" width="100%" alt=""  />
            </div>
            <div className="swiper-slide">
              <img src="https://english.cdn.zeenews.com/static/public/zee-news-new-logo.jpg" className="img-fluid" width="100%" alt="" />
            </div>
            <div className="swiper-slide">
              <img src="https://startupstorymedia.com/wp-content/themes/startupstory/img/logo-2.png" className="img-fluid" width="100%" alt="" />
            </div>
            <div className="swiper-slide">
              <img src="./images/pp.webp" className="img-fluid" alt="" />
            </div>
            <div className="swiper-slide">
              <img src="https://images.news18.com/dlxczavtqcctuei/news18/static/images/news18marathi-desktop.png" className="img-fluid" alt="" />
            </div>
            <div className="swiper-slide">
              <img src="https://cms.patrika.com/wp-content/uploads/static-assets/new-patrika-logo.png" className="img-fluid" alt="" />
            </div>
            <div className="swiper-slide">
              <img src="https://hindi.cdn.zeenews.com/hindi/sites/all/themes/desktop_main_th/images/zee_bharat_logo.png" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    </Zoom>
  );
};

export default ClientsSection;
