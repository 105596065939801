import React from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "../style/testimonial.css";
import { Zoom } from "react-awesome-reveal";

const Reviews = () => {
  const reviews = [
    {
      head: "Excellent App!",
      rating: "",
      text: "I Was just scrolling through my phone some days before and found this app, and I am glad I came across this. Have been a die heart fan of bhandara since I was a child but always had a difficult time finding about them. But then this app helped me. Thankyou for the amazing experiences.",
      name: "Shailesh Jabrolia",
      profileImg:
        "https://desklib.com/static/src/assets/images/v2/profile_1.svg",
    },
    {
      head: "Best App!",

      text: "Damn. This is the best app for free food!! In a month I spent a lot on online food delivery apps, but with this, I get free food all day!! I'm saving more money now. Thanks bhandara!",
      name: "Jeffery Jhon",
      profileImg:
        "https://desklib.com/static/src/assets/images/v2/profile_3.svg",
      rating: "",
    },
    {
      head: "This App is easy to use!",
      text: "Overall experience is Very good and responsive app. Please give it a try",
      name: "Sampada Khandelwal",
      profileImg:
        "https://desklib.com/static/src/assets/images/v2/profile_2.svg",
      rating: "",
    },
  ];

  return (
    <Zoom triggerOnce={true}>
      <section className="pn_reviews my-5">
        <div className="app">
          <h1 className="text-center text-dark app">
            <b>Review From our Users</b>
          </h1>
        </div>
        <div className="container">
          <div className="row">
            {reviews.map((review, index) => (
              <div key={index} className="col-md-12 col-lg-4">
                <div className="pn_testimonial_box mt-4">
                  <h2>
                    <b>{review.head} </b>
                  </h2>
                  <span>
                    {review.rating}
                    <span className="fa fa-star checked rate"></span>
                    <span className="fa fa-star checked rate"></span>
                    <span className="fa fa-star checked rate"></span>
                    <span className="fa fa-star rate"></span>
                    <span className="fa fa-star rate"></span>
                  </span>
                  <p className="text-justify"> {review.text} </p>
                  <div className="row pt-3">
                    <div className="col-md-6 pn_review_profile">
                      <img
                        src={review.profileImg}
                        className="border-radius-12 float-right"
                        alt="client image"
                      />
                      <span className="rate">
                        <b>{review.name}</b>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Zoom>
  );
};

export default Reviews;
