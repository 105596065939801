import React from "react";
import Footer from "../components/Footer";
import "../style/team.css";
import { Zoom } from "react-awesome-reveal";
import { useState } from "react";
import { AppRoutes } from "../routes/routes";
import { metaTagsData } from "../routes/metaTagsData";
import { SeoComponentsBuilder } from "../components/seoComponent/SeoComponentsBuilder";

const Nri = () => {
  return (
    <>
      <SeoComponentsBuilder metaTags={metaTagsData[AppRoutes.nri]} />

      <Zoom triggerOnce={true}>
        <section className="ban_sec">
          <br></br>
          <br></br>
          <div className="ban_img">
            <img src="/images/4.jpg" alt="banner" border="0" />
            <div className="ban_text"></div>
          </div>
        </section>

        <div>
          <h2 className="text-accent text-center">
            <b>Punya plan</b>
          </h2>
          <p className="fs-500 text-center">plans that work for everyone</p>
          <div className="plans flow-content">
            <div className="plan plan--light">
              <h2 className="plan-title">
                <b>Prashadam</b>
              </h2>
              <p className="plan-price text-center">
                $501<span></span>
              </p>
              <p className="plan-description">
                <h6>
                  {" "}
                  <i className="fa-solid fa-angles-right"></i> Customise meals.
                </h6>
                <h6>
                  <i className="fa-solid fa-angles-right"></i> For 100+ peoples.
                </h6>
                <h6>
                  <i className="fa-solid fa-angles-right"></i> book plan Before
                  3 days.
                </h6>
                <h6>
                  <i className="fa-solid fa-angles-right"></i> Provides
                  Prashadam Photos.
                </h6>
              </p>

              <button className="btn btn_all mt-4">
                <a
                  target="_blank"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdsiFMuxK_FtpYUVs_meUk1INgcgTTu7cIQV6pZF9PtfZKKPA/viewform?usp=sf_link"
                >
                  Punya Karo <i className="fa-solid fa-angle-right ms-1"></i>
                </a>
              </button>
            </div>

            <div className="plan plan--accent">
              <h2 className="plan-title text-center text-white">
                <b>bhoj</b>
              </h2>
              <p className="plan-price text-white text-center">
                $1100<span></span>
              </p>
              <p className="plan-description">
                <h6 className="text-white">
                  {" "}
                  <i className="fa-solid fa-angles-right"></i> Customise meals.
                </h6>
                <h6 className="text-white">
                  {" "}
                  <i className="fa-solid fa-angles-right"></i> For 250+ peoples.
                </h6>
                <h6 className="text-white">
                  {" "}
                  <i className="fa-solid fa-angles-right"></i> book plan Before
                  7 days.
                </h6>
                <h6 className="text-white">
                  {" "}
                  <i className="fa-solid fa-angles-right"></i> Provides Bhoj
                  Photos/videos.
                </h6>
                <h6 className="text-white">
                  {" "}
                  <i className="fa-solid fa-angles-right"></i> Customise
                  Location.
                </h6>
              </p>

              <button className="btn btn_all">
                <a
                  target="_blank"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdsiFMuxK_FtpYUVs_meUk1INgcgTTu7cIQV6pZF9PtfZKKPA/viewform?usp=sf_link"
                >
                  Punya Karo <i className="fa-solid fa-angle-right ms-1"></i>
                </a>
              </button>
              {/* <button className="btn btn_all"><a href='/NriForm'>Punya Karo <i className="fa-solid fa-angle-right ms-1"></i></a></button> */}
            </div>

            <div className="plan plan--light">
              <h2 className="plan-title text-center">
                <b>bhandara</b>
              </h2>
              <p className="plan-price text-center">
                $2100<span></span>
              </p>
              <p className="plan-description">
                <h6>
                  {" "}
                  <i className="fa-solid fa-angles-right"></i> Customise meals.
                </h6>
                <h6>
                  <i className="fa-solid fa-angles-right"></i> For 1000+
                  peoples.
                </h6>
                <h6>
                  <i className="fa-solid fa-angles-right"></i> book plan Before
                  14 days.
                </h6>
                <h6>
                  <i className="fa-solid fa-angles-right"></i> Provides Bhandara
                  Photos/Videos.
                </h6>
                <h6>
                  <i className="fa-solid fa-angles-right"></i> Customise
                  Location.
                </h6>
              </p>

              <button className="btn btn_all">
                <a
                  target="_blank"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdsiFMuxK_FtpYUVs_meUk1INgcgTTu7cIQV6pZF9PtfZKKPA/viewform?usp=sf_link"
                >
                  Punya Karo <i className="fa-solid fa-angle-right ms-1"></i>
                </a>
              </button>
            </div>
          </div>
        </div>
      </Zoom>
      {/* </div> */}
    </>
  );
};

export default Nri;
