import React from "react";
import Footer from "../components/Footer";
import "../style/team.css";
import { Zoom } from "react-awesome-reveal";
import { AppRoutes } from "../routes/routes";
import { metaTagsData } from "../routes/metaTagsData";
import { SeoComponentsBuilder } from "../components/seoComponent/SeoComponentsBuilder";
const Corporate = () => {
  return (
    <>
      <SeoComponentsBuilder metaTags={metaTagsData[AppRoutes.corporate]} />

      <Zoom triggerOnce={true}>
        <section className="ban_sec">
          <br></br>
          <br></br>
          <div className="ban_img">
            <img src="/images/99.jpg" alt="banner" border="0" />
            <div className="ban_text"></div>
          </div>
        </section>

        <h2 className="text-accent text-center">
          <b>Choose A Plan</b>
        </h2>
        <p className="fs-500 text-center">
          Advertise yourself like none other organise a bhandara.
        </p>
        <div className="plans flow-content">
          <div className="plan plan--light">
            <h2 className="plan-title text-center">
              <b>Basic </b>
            </h2>
            {/* <p className="plan-price text-center">&#8377; /Boxes<span></span></p> */}
            <p className="plan-description">
              <h6>
                <i className="fa-solid fa-angles-right"></i> Customise meals.
              </h6>
              <h6>
                <i className="fa-solid fa-angles-right"></i> Your branding on
                free food boxes
              </h6>
              <h6>
                <i className="fa-solid fa-angles-right"></i> book plan Before 3
                days.
              </h6>
              <h6>
                <i className="fa-solid fa-angles-right"></i> Coverage on Social
                Media
              </h6>
            </p>
            <br />
            <br /> <br />
            <div className="main_btn corporate ">
              <button className="btn btn_all mt-4">
                <a
                  target="_blank"
                  href="https://docs.google.com/forms/d/e/1FAIpQLScRqn9ChARATp79p4aEKkj3kynjR9yAhSXn3fTl70028MPP6A/viewform?usp=sf_link"
                >
                  Enquiry<i className="fa-solid fa-angle-right ms-1"></i>
                </a>
              </button>
            </div>
          </div>

          <div className="plan plan--accent">
            <h2 className="plan-title text-center text-white">
              <b>Elite</b>
            </h2>
            {/* <p className="plan-price text-white text-center">&#8377; /Boxes<span></span></p> */}
            <p className="plan-description">
              <h6 className="text-white">
                <i className="fa-solid fa-angles-right"></i> Customise meals.
              </h6>
              <h6 className="text-white">
                <i className="fa-solid fa-angles-right"></i> Your ads on our app
              </h6>
              <h6 className="text-white">
                <i className="fa-solid fa-angles-right"></i> Your branding on
                free food boxes
              </h6>
              <h6 className="text-white">
                <i className="fa-solid fa-angles-right"></i> Coverage on Social
                Media
              </h6>
            </p>
            <br />
            <br />
            <br />
            <br /> <br />
            <div className="main_btn">
              <button className="btn btn_all">
                <a
                  target="_blank"
                  href="https://docs.google.com/forms/d/e/1FAIpQLScRqn9ChARATp79p4aEKkj3kynjR9yAhSXn3fTl70028MPP6A/viewform?usp=sf_link"
                >
                  Enquiry<i className="fa-solid fa-angle-right ms-1"></i>
                </a>
              </button>
            </div>
          </div>

          <div className="plan plan--light">
            <h2 className="plan-title text-center">
              <b>Ultimate</b>
            </h2>
            {/* <p className="plan-price text-center">&#8377; /Boxes<span></span></p> */}
            <p className="plan-description">
              <h6>
                {" "}
                <i className="fa-solid fa-angles-right"></i> Customise meals.
              </h6>
              <h6>
                {" "}
                <i className="fa-solid fa-angles-right"></i> Minimum 1000+
                peoples.
              </h6>
              <h6>
                <i className="fa-solid fa-angles-right"></i> Your ads on our app
              </h6>
              <h6>
                <i className="fa-solid fa-angles-right"></i> Your branding on
                free food boxes
              </h6>
              <h6>
                <i className="fa-solid fa-angles-right"></i> Main stream media
                coverage for organizing a large scale bhandara
              </h6>
              <h6>
                <i className="fa-solid fa-angles-right"></i> Coverage on Social
                Media
              </h6>
            </p>

            <div className="main_btn">
              <button className="btn btn_all">
                <a
                  target="_blank"
                  href="https://docs.google.com/forms/d/e/1FAIpQLScRqn9ChARATp79p4aEKkj3kynjR9yAhSXn3fTl70028MPP6A/viewform?usp=sf_link"
                >
                  Enquiry<i className="fa-solid fa-angle-right ms-1"></i>
                </a>
              </button>
            </div>
          </div>
        </div>
      </Zoom>
      {/* </div> */}
    </>
  );
};

export default Corporate;
