import React from 'react';
import { Zoom } from "react-awesome-reveal";
import '../style/contact.css'

function mobileadd() {
  return (
    <Zoom triggerOnce={true}>
<div className='app'>


<h1 className='text-center text-dark app'><b>Download Bhandara app</b></h1></div>

<h6 className='text-center mt-2' >India’s 1st free-food location and spritual ONDC app & Bhandara location sharing social media app.</h6><br></br>
<div className=' play '>
          <a href='https://play.google.com/store/apps/details?id=com.bhandara&hl=en_US' target='_blank'><img src='/images/playstore.png' className='h-10 w-40 rounded-lg'   /></a>
</div>
  <section className="hero_section " id="hero">

      </section>


    </Zoom>
  );
}

export default mobileadd;
