import React from 'react';
import { Zoom } from "react-awesome-reveal";

function StorySection() {
  return (
    <Zoom triggerOnce={true}>

    <section id="Story" className="about_section mb-5 py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 col-xxl-6">
            <div className="about_img">
              <img src="/images/SQUARE.jpg" alt="aboutimage" />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-xxl-6">
            <div className="about_content text-center">
              <h2 className="main_heading text-center ">
                We Pride Ourselves on marketing the best food ingredients
              </h2>
              <p className="main_pra ">
              We honor the centuries-old custom of communal a meal at our "Bhandara," 
              where each meal is a celebration of family and community. This custom, 
              which has its roots in the center of India's cultural as stands for consistency, giving, and the delight of sharing.


              </p>
            </div>
            {/* <div className="main_btn about_btn mx-auto w-50">
              <button className="btn btn_all">
                <a>Learn More<i className="fa-solid fa-rotate-right ms-3"></i></a>
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </section>
    </Zoom>
  );
}

export default StorySection;
