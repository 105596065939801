import React from "react";
import "../style/team.css";
import { Zoom } from "react-awesome-reveal";
import { AppRoutes } from "../routes/routes";
import { metaTagsData } from "../routes/metaTagsData";
import { SeoComponentsBuilder } from "../components/seoComponent/SeoComponentsBuilder";

const ONDC = () => {
  return (
    <>
      <SeoComponentsBuilder metaTags={metaTagsData[AppRoutes.ondc]} />

      <Zoom triggerOnce={true}>
        <section className="ban_sec">
          <br></br>
          <br></br>
          <div className="ban_img">
            <img src="/images/ONDCC.png" alt="banner" border="0" />
            <div className="ban_text"></div>
          </div>
        </section>

        {/* <section>
          <div className="container ">
            <div className="">
              <br></br>
              <br></br>
              <h2 className="text-center ">Why partner with BhandaraApp ?</h2>

              <div className="pb-3 my-3">With BhandaraApp you shall receive the branding authority for your store. BhandaraApp which has become an overtime sensation among consumers of all sections of society will have a massive spike in sales of the store.</div>
              <p className="pb-3">
                With the vision to serve pure vegetarian food, there won’t be hassle around managing much of the inventory. As a partner to the BhandaraApp the stores will also be supported at the backend inventory supply.
              </p>
              <p>The stores will have provisions to take in orders through apps for delivery which will surface the stores online under its association with BhandaraApp branding</p>
              <p className="pb-3">Being partnered with BhandaraApp which is a known sensation among customers will lead to increased footfall in the store by virtue of the services and popularity.</p>


              <div className="btm-policies-div">
                <h2 className="text-center ">Models for the BhandaraApp</h2>

                <p className="pb-3">The *Aggregator model* will have the establishment, wholly owned and operated by the BhandaraApp, function as another outlet of the BhandaraApp. From its inception to day-to-day operations, the BhandaraApp oversees all aspects, making it distinct from franchise locations.</p>
                <p className="pb-3">The *Franchise model* will have the BhandaraApp exclusively license its Brand Name to the Franchise operator for a predetermined period in exchange for a specific non-refundable fee, commonly known as the 'Franchise Fee'.”</p>

                <h2 className="text-center ">How to apply ?</h2>
                <div className='p-2'> 
                  <ol>
                    <p>To apply for a BhandaraApp franchise, follow these steps:</p>
                    <li>Visit the official BhandaraApp website to express your interest in franchising. </li>
                    <br />

                    <li>Upon reaching the designated page, locate the franchise application form. </li>
                    <br />


                    <li>Complete the BhandaraApp franchise application form with all required details. </li>
                    <br />

                    <li>Ensure all necessary information is provided accurately. </li>
                    <br />

                    <li>Upon submission, you'll receive a confirmation message thanking you for your submission. </li>
                    <br />

                    <li>If your application is shortlisted, our Franchise team will reach out to you for further discussion. </li>


                  </ol>
                </div>

              </div>

            </div>
          </div>
        </section> */}
      </Zoom>
      {/* </div> */}
    </>
  );
};

export default ONDC;
