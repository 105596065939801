import React from 'react';
import '../style/nav.css'
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav>
      <div className="wrapper">
        <div className="logo"> <a href='/'> <img className=" w-43 h-16"  src="/images/blogo.png"  alt=""   /></a></div>
        <input type="radio" name="slider" id="menu-btn" />
        <input type="radio" name="slider" id="close-btn" />
        <ul className="nav-links">
          <label htmlFor="close-btn" className="btn close-btn"><i className="fas fa-times"></i></label>
          <li><a href="/">Home</a></li>
          {/* <li>
            <a href="#" className="desktop-item">Dropdown Menu</a>
            <input type="checkbox" id="showDrop" />
            <label htmlFor="showDrop" className="mobile-item">Dropdown Menu</label>
            <ul className="drop-menu">
              <li><a href="#">Drop menu 1</a></li>
              <li><a href="#">Drop menu 2</a></li>
              <li><a href="#">Drop menu 3</a></li>
              <li><a href="#">Drop menu 4</a></li>
            </ul>
          </li> */}
          <li>
            <a href="#" className="desktop-item arrow">Products  <i className="fa-solid fa-angle-down"></i></a>
            <input type="checkbox" id="showMega" />
            <label htmlFor="showMega" className="mobile-item arrow"><b>Products <i className="fa-solid fa-angle-down"></i></b></label>
            <div className="mega-box">
              <div className="content">
                <div className="row">
                <header><b>For Bussiness</b></header>

                <a href='/Corporate'  >
                  <img src="/images/corporate.png" alt="" />

                  </a>
                  <a href='/Corporate'>For Corporate</a>
                </div>
                {/* <div className="row">
                  <img src="/ONDC.png" alt="" />
                </div> */}
                
                <div className="row">
                  <header><b>For Customers</b></header>
                  <ul className="mega-links">
                    <li><a href="#"><i className="fa-solid fa-check fa-beat-fade"></i> Find Free Food</a></li>
                    <li><a href="#"><i className="fa-solid fa-check fa-beat-fade"></i> Share Bhandara Location</a></li>
                    <li><a href="#"><i className="fa-solid fa-check fa-beat-fade"></i> Online Bhandara Karwao</a></li>
                    <li><a href="#"><i className="fa-solid fa-check fa-beat-fade"></i> Donation</a></li>
                  </ul>
                </div>
                <div className="row">
                  <header></header>
                  <ul className="mega-links">
                    <li><a href="#"><i className="fa-solid fa-check fa-beat-fade"></i> Punya Insurance</a></li>
                    <li><a href="#"><i className="fa-solid fa-check fa-beat-fade"></i> Community</a></li>
                    <li><a href="#"><i className="fa-solid fa-check fa-beat-fade"></i> Play & Earn Food</a></li>
                    <li><a href="/ONDC"><i className="fa-solid fa-check fa-beat-fade"></i> ONDC</a></li>
                  </ul>
                </div>
                {/* <div className="row">
                  <header>Security services</header>
                  <ul className="mega-links">
                    <li><a href="#">Site Seal</a></li>
                    <li><a href="#">VPS Hosting</a></li>
                    <li><a href="#">Privacy Seal</a></li>
                    <li><a href="#">Website design</a></li>
                  </ul>
                </div> */}
              </div>
            </div>
          </li>
          <li><a href="/Nri">NRI Portal</a></li>
          <li><a href="/ONDC">ONDC</a></li>
          <li><a href="/Blog">Blogs</a></li>
          <li><a href="/Contact">Get in Touch 👋</a></li>


        </ul>
        <label htmlFor="menu-btn" className="btn menu-btn"><i className="fas fa-bars"></i></label>
      </div>
    </nav>
  );
}


export default Navbar;



// import { Fragment, useState } from 'react'
// import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
// import { Bars3Icon, MagnifyingGlassIcon, ShoppingBagIcon, XMarkIcon } from '@heroicons/react/24/outline'

// const navigation = {
//   categories: [
//     {
//       id: 'Company',
//       name: 'Products',
//       featured: [
//         {
//           name: 'For Corporate',
//           href: '/Corporate',
//           imageSrc: '/corporate.png',
//         },
//         {
//           name: 'For ONDC',
//           href: '/ONDC',
//           imageSrc: '/ONDC.png',
          
//         },
//       ],
//       sections: [
//         {
//           id: 'Products',
//           items: [
//             { name: 'Find Free Food', href: '#' },
//             { name: 'Share Bhandara Location', href: '#' },
//             { name: 'Online Bhandara Karwao', href: 'https://docs.google.com/forms/d/e/1FAIpQLSe4qZG1_oOTRCMOXj9PyuqwIGtdRcHFTX6-ZyU78116jTbjFQ/viewform' },
//             { name: 'Donation', href: '#' },



//           ],
//         },
//         {
//           id: 'accessories',
//           name: '',
//           items: [
//             { name: 'Punya Insurance', href: '#' },
//             { name: 'Community', href: '#' },
//             { name: 'Play & Earn Food', href: '#' },
//             { name: 'ONDC', href: '#' },


//           ],
//         },
//         // {
//         //   id: 'brands',
//         //   name: 'Brands',
//         //   items: [
//         //     { name: 'Full Nelson', href: '#' },
//         //     { name: 'My Way', href: '#' },
//         //     { name: 'Re-Arranged', href: '#' },
//         //     { name: 'Counterfeit', href: '#' },
//         //     { name: 'Significant Other', href: '#' },
//         //   ],
//         // },
//       ],
//     },
//     // {
//     //   id: 'men',
//     //   name: 'NRI',
//     //   featured: [
//     //     {
//     //       name: 'New Arrivals',
//     //       href: '#',
//     //       imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg',
//     //       imageAlt: 'Drawstring top with elastic loop closure and textured interior padding.',
//     //     },
//     //     {
//     //       name: 'Artwork Tees',
//     //       href: '#',
//     //       imageSrc: 'https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg',
//     //       imageAlt:
//     //         'Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.',
//     //     },
//     //   ],
//     //   sections: [
//     //     {
//     //       id: 'clothing',
//     //       name: 'Clothing',
//     //       items: [
//     //         { name: 'Tops', href: '#' },
//     //         { name: 'Pants', href: '#' },
//     //         { name: 'Sweaters', href: '#' },
//     //         { name: 'T-Shirts', href: '#' },
//     //         { name: 'Jackets', href: '#' },
//     //         { name: 'Activewear', href: '#' },
//     //         { name: 'Browse All', href: '#' },
//     //       ],
//     //     },
//     //     {
//     //       id: 'accessories',
//     //       name: 'Accessories',
//     //       items: [
//     //         { name: 'Watches', href: '#' },
//     //         { name: 'Wallets', href: '#' },
//     //         { name: 'Bags', href: '#' },
//     //         { name: 'Sunglasses', href: '#' },
//     //         { name: 'Hats', href: '#' },
//     //         { name: 'Belts', href: '#' },
//     //       ],
//     //     },
//     //     {
//     //       id: 'brands',
//     //       name: 'Brands',
//     //       items: [
//     //         { name: 'Re-Arranged', href: '#' },
//     //         { name: 'Counterfeit', href: '#' },
//     //         { name: 'Full Nelson', href: '#' },
//     //         { name: 'My Way', href: '#' },
//     //       ],
//     //     },
//     //   ],
//     // },
//   ],
//   pages: [
//     { name: 'NRI Portal', href: '/Nri' },

//     { name: 'ONDC ', href: '/ONDC' },
//     { name: 'Blogs ', href: '/Blog' },

//     { name: 'Get in touch 👋 ', href: '/Contact' },

//   ],
// }

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

// export default function Example() {
//   const [open, setOpen] = useState(false)

//   return (
//     <div className="bg-white">
//       {/* Mobile menu */}
//       <Transition.Root show={open} as={Fragment}>
//         <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
//           <Transition.Child
//             as={Fragment}
//             enter="transition-opacity ease-linear duration-300"
//             enterFrom="opacity-0"
//             enterTo="opacity-100"
//             leave="transition-opacity ease-linear duration-300"
//             leaveFrom="opacity-100"
//             leaveTo="opacity-0"
//           >
//             <div className="fixed inset-0 bg-black bg-opacity-25" />
//           </Transition.Child>

//           <div className="fixed inset-0 z-40 flex">
//             <Transition.Child
//               as={Fragment}
//               enter="transition ease-in-out duration-300 transform"
//               enterFrom="-translate-x-full"
//               enterTo="translate-x-0"
//               leave="transition ease-in-out duration-300 transform"
//               leaveFrom="translate-x-0"
//               leaveTo="-translate-x-full"
//             >
//               <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
//                 <div className="flex px-4 pb-2 pt-5">
//                   <button
//                     type="button"
//                     className="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
//                     onClick={() => setOpen(false)}
//                   >
//                     <span className="absolute -inset-0.5" />
//                     <span className="sr-only">Close menu</span>
//                     <XMarkIcon className="h-6 w-6" aria-hidden="true" />
//                   </button>
//                 </div>

//                 {/* Links */}
//                 <Tab.Group as="div" className="mt-2">
//                   <div className=" border-gray-200">
//                     <Tab.List className="-mb-px flex space-x-8 px-4">
//                       {navigation.categories.map((category) => (
//                         <Tab
//                           key={category.name}
//                           className={({ selected }) =>
//                             classNames(
//                               selected ? 'border-indigo-600 text-indigo-600' : 'border-transparent text-gray-900',
//                               'flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-base font-medium'
//                             )
//                           }
//                         >
//                           {category.name}
//                         </Tab>
//                       ))}
//                     </Tab.List>
//                   </div>
//                   <Tab.Panels as={Fragment}>
//                     {navigation.categories.map((category) => (
//                       <Tab.Panel key={category.name} className="space-y-10 px-4 pb-8 pt-10">
//                         <div className="grid grid-cols-2 gap-x-4">
//                           {category.featured.map((item) => (
//                             <div key={item.name} className="group relative ">
//                               <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
//                                 <img src={item.imageSrc} alt={item.imageAlt} className="object-cover object-center" />
//                               </div>
//                               <a href={item.href} className="mt-6 block font-medium text-gray-900">
//                                 <span className="absolute inset-0 z-10" aria-hidden="true" />
//                                 {item.name}
//                               </a>
//                               {/* <p aria-hidden="true" className="mt-1">
//                                 Shop Now
//                               </p> */}
//                             </div>
//                           ))}
//                         </div>
//                         {category.sections.map((section) => (
//                           <div key={section.name}>
//                             <p id={`${category.id}-${section.id}-heading-mobile`} className="font-medium text-gray-900">
//                               {section.name}
//                             </p>
//                             <ul
//                               role="list"
//                               aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
//                               className="mt-6 flex flex-col space-y-6"
//                             >
//                               {section.items.map((item) => (
//                                 <li key={item.name} className="flow-root">
//                                   <a href={item.href} className="-m-2 block p-2 text-gray-500">
//                                     {item.name}
//                                   </a>
//                                 </li>
//                               ))}
//                             </ul>
//                           </div>
//                         ))}
//                       </Tab.Panel>
//                     ))}
//                   </Tab.Panels>
//                 </Tab.Group>

//                 <div className="space-y-6 border-t border-gray-200 px-4 py-6">
//                   {navigation.pages.map((page) => (
//                     <div key={page.name} className="flow-root">
//                       <a href={page.href} className="-m-2 block p-2 font-medium text-gray-900">
//                         {page.name}
//                       </a>
//                     </div>
//                   ))}
//                 </div>

//                 <div className="space-y-6 border-t border-gray-200 px-4 py-6">
//                   {/* <div className="flow-root">
//                     <a href="#" className="-m-2 block p-2 font-medium text-gray-900">
//                       Sign in
//                     </a>
//                   </div> */}
//                   {/* <div className="flow-root">
//                     <a href="#" className="-m-2 block p-2 font-medium text-gray-900">
//                       Create account
//                     </a>
//                   </div> */}
//                 </div>

//                 {/* <div className="border-t border-gray-200 px-4 py-6">
//                   <a href="#" className="-m-2 flex items-center p-2">
//                     <img
//                       src="https://tailwindui.com/img/flags/flag-canada.svg"
//                       alt=""
//                       className="block h-auto w-5 flex-shrink-0"
//                     />
//                     <span className="ml-3 block text-base font-medium text-gray-900">CAD</span>
//                     <span className="sr-only">, change currency</span>
//                   </a>
//                 </div> */}
//               </Dialog.Panel>
//             </Transition.Child>
//           </div>
//         </Dialog>
//       </Transition.Root>

//       <header className="relative bg-white">
//         {/* <p className="flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
//           Get free delivery on orders over $100
//         </p> */}

//         <nav aria-label="Top" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
//           <div className="border-b border-gray-200">
//             <div className="flex  items-center justify-evenly">
//               <button
//                 type="button"
//                 className="relative rounded-md bg-white p-2 text-gray-400 lg:hidden"
//                 onClick={() => setOpen(true)}
//               >
//                 <span className="absolute -inset-0.5" />
//                 <span className="sr-only">Open menu</span>
//                 <Bars3Icon className="h-6 w-6" aria-hidden="true" />
//               </button>

//               {/* Logo */}
//               <div className="ml-4 flex lg:ml-0">
//                <a href='/'> <img
//                   className=" w-43 h-16"
//                   src="/blogo.png"
//                   alt=""
//                 />
//                 </a>
//               </div>

//               {/* Flyout menus */}
//               <Popover.Group className="hidden lg:ml-30 lg:block lg:self-stretch">
//                 <div className="flex h-full space-x-8 ml-80 drop	">
//                   {navigation.categories.map((category) => (
//                     <Popover key={category.name} className="flex  ">
//                       {({ open }) => (
//                         <>
//                           <div className="relative flex   ">
//                             <Popover.Button
//                               className={classNames(
//                                 open
//                                   ? ' text-indigo-600  '
//                                   : ' text-gray-700 hover:text-gray-800 ',
//                                 'relative z-10 -mb-px flex items-center border-b-2 pt-px font-medium  transition-colors duration-200 ease-out'
//                               )}
//                             >
//                               {category.name}
//                               <i className="fa-solid fa-angle-up mx-2 "></i>
//                             </Popover.Button>
//                           </div>

//                           <Transition
//                             as={Fragment}
//                             enter="transition ease-out duration-200"
//                             enterFrom="opacity-0"
//                             enterTo="opacity-100"
//                             leave="transition ease-in duration-150"
//                             leaveFrom="opacity-100"
//                             leaveTo="opacity-0"
//                           >
//                             <Popover.Panel className="absolute inset-x-0 z-10 top-full  text-gray-500 dropdown-content">

//                               {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
//                               <div className="absolute inset-0 top-1/2 bg-white shadow " aria-hidden="true" />

//                               <div className="relative bg-white ">
//                                 <div className="mx-auto max-w-7xl px-8 ">

//                                   <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-8">
//                                     <div>
//                                       <h3 className='mt-5 text-black my-6'><b>FOR BUSSINESS</b></h3>

//                                       <div className="col-start-2 grid grid-cols-2 gap-x-8">
//                                         {category.featured.map((item) => (
//                                           <div key={item.name} className="group relative text-base ">

//                                             <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">

//                                               <img
//                                                 src={item.imageSrc}
//                                                 alt={item.imageAlt}
//                                                 className="object-cover object-center"
//                                               />
//                                             </div>
//                                             <a href={item.href} className="mt-6 block font-medium text-gray-900">
//                                               <span className="absolute inset-0 z-10" aria-hidden="true" />
//                                               {item.name}
                                             
//                                             </a>
//                                             {/* <p aria-hidden="true" className="mt-1">
//                                               Apply Now
//                                             </p> */}

                                            
                                           
                                            
                                         
//                                           </div>
                                          
//                                         ))}
//                                       </div>
//                                     </div>
//                                     <div className='my-0'>
//                                       <h3 className='mt-5 text-black'><b>FOR CUSTOMERS</b></h3>


//                                       <div className="row-start-1 grid grid-cols-2 gap-x-8 gap-y-10 ">

//                                         {category.sections.map((section) => (
//                                           <div key={section.name}>
//                                             <p id={`${section.name}-heading`} className="font-medium text-gray-900">
//                                               {section.name}
//                                             </p>
//                                             <ul
//                                               role="list"
//                                               aria-labelledby={`${section.name}-heading`}
//                                               className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
//                                             >
//                                               {section.items.map((item) => (
//                                                 <li key={item.name} className="flex dropdown">
//                                                   <a href={item.href} className="hover:text-gray-800">
//                                                     {item.name}
//                                                   </a>
//                                                 </li>
//                                               ))}
//                                             </ul>
//                                           </div>
//                                         ))}
//                                       </div>

//                                     </div>
//                                   </div>
//                                 </div>
//                               </div>
//                             </Popover.Panel>
//                           </Transition>
//                         </>
//                       )}
//                     </Popover>
//                   ))}

//                   {navigation.pages.map((page) => (
//                     <a
//                       key={page.name}
//                       href={page.href}
//                       className="flex items-center  font-medium text-gray-700 hover:text-gray-800 "
//                     >
//                       {page.name}
//                     </a>
//                   ))}
//                 </div>
//               </Popover.Group>

//               {/* <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
//                   <a href="#" className="text-sm font-medium text-gray-700 hover:text-gray-800">
//                     Sign in
//                   </a>
//                   <span className="h-6 w-px bg-gray-200" aria-hidden="true" />
//                   <a href="#" className="text-sm font-medium text-gray-700 hover:text-gray-800">
//                     Create account
//                   </a>
//                 </div> */}

//               {/* <div className="hidden lg:ml-8 lg:flex">
//                   <a href="#" className="flex items-center text-gray-700 hover:text-gray-800">
//                     <img
//                       src="https://tailwindui.com/img/flags/flag-canada.svg"
//                       alt=""
//                       className="block h-auto w-5 flex-shrink-0"
//                     />
//                     <span className="ml-3 block text-sm font-medium">CAD</span>
//                     <span className="sr-only">, change currency</span>
//                   </a>
//                 </div> */}

//               {/* Search */}
//               {/* <div className="flex lg:ml-6">
//                   <a href="#" className="p-2 text-gray-400 hover:text-gray-500">
//                     <span className="sr-only">Search</span>
//                     <MagnifyingGlassIcon className="h-6 w-6" aria-hidden="true" />
//                   </a>
//                 </div> */}

//               {/* Cart */}
//               {/* <div className="ml-4 flow-root lg:ml-6">
//                   <a href="#" className="group -m-2 flex items-center p-2">
//                   <i className="fa-regular fa-user"></i>
//                     <ShoppingBagIcon
//                       className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
//                       aria-hidden="true"
//                     />
//                     <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">0</span>
//                     <span className="sr-only">items in cart, view bag</span>
//                   </a>
//                 </div> */}
//             </div>
//           </div>
//         </nav>
//       </header>
//     </div>
//   )
// }
